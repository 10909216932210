import React, { Component, Suspense } from "react";
import { Route, BrowserRouter as Router, withRouter, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import createCookie from "./helpers/createCookie";
import "./index.scss";
import LoaderComponent from "./components/LoaderComponent";

// Components
const HeaderComponent = React.lazy(() => import("./components/HeaderComponent"));
const ScrollToTopComponent = React.lazy(() => import("./components/ScrollToTopComponent"));
const FooterComponent = React.lazy(() => import("./components/FooterComponent"));
const PromotionBarComponent = React.lazy(() => import("./components/PromotionBarComponent"));
//const ExitModalComponent = React.lazy(() => import("./components/ExitModalComponent"));

// Pages
const HomePage = React.lazy(() => import("./pages/HomePage"));
const AboutPage = React.lazy(() => import("./pages/AboutPage"));
const BookingPage = React.lazy(() => import("./pages/BookingPage"));
const DestinationsPage = React.lazy(() => import("./pages/DestinationsPage"));
const HelpCenterPage = React.lazy(() => import("./pages/HelpCenterPage"));
const BookingSummaryPage = React.lazy(() => import("./pages/BookingSummaryPage"));
const AirportTransfer = React.lazy(() => import("./pages/AirportTransfer"));
const PrivacyPolicyPage = React.lazy(() => import("./pages/PrivacyPolicyPage"));
const TermsAndConditionsPage = React.lazy(() => import("./pages/TermsAndConditionsPage"));
const GroupTransferPage = React.lazy(() => import("./pages/GroupTransferPage"));
const PremiumTransferPage = React.lazy(() => import("./pages/PremiumTransferPage"));
const CoronaVirusUpdatePage = React.lazy(() => import("./pages/CoronaVirusUpdatePage"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));

// Landing pages
const BudapestToViennaTransferPage = React.lazy(() => import("./pages/landing-pages/BudapestToViennaTransferPage"));
const BudapestToDebrecenTransferPage = React.lazy(() => import("./pages/landing-pages/BudapestToDebrecenTransferPage"));
const ViennaToBudapestTransferPage = React.lazy(() => import("./pages/landing-pages/ViennaToBudapestTransferPage"));
const BudapestToBratislavaTransferPage = React.lazy(() => import("./pages/landing-pages/BudapestToBratislavaTransferPage"));
const BudapestToHevizTransferPage = React.lazy(() => import("./pages/landing-pages/BudapestToHevizTransferPage"));

const HeaderComponentWithRoute = withRouter((props) => <HeaderComponent {...props} />);
const ScrollToTopComponentWithRoute = withRouter((props) => <ScrollToTopComponent {...props} />);
//const ExitModalComponentWithRoute = withRouter((props) => <ExitModalComponent {...props} />);

export default class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mouseOut: false,
        };

        this.setUtmCookie();
    }

    setUtmCookie() {
        const urlParams = new URLSearchParams(window.location.search);

        const utm_source = urlParams.get("utm_source");
        const utm_medium = urlParams.get("utm_medium");
        const utm_campaign = urlParams.get("utm_campaign");
        const utm_term = urlParams.get("utm_term");
        const utm_content = urlParams.get("utm_content");

        const data = {
            utm_source: utm_source || "",
            utm_medium: utm_medium || "",
            utm_campaign: utm_campaign || "",
            utm_term: utm_term || "",
            utm_content: utm_content || "",
        };

        if (utm_source || utm_medium || utm_campaign || utm_term || utm_content) {
            createCookie("trackingData", JSON.stringify(data), 2);
        }
    }

    render() {
        return (
            <Router>
                <Helmet>
                    <title>Welcome to Pickup Hungary - Private transfer in Hungary</title>
                </Helmet>

                <Suspense fallback={<LoaderComponent />}>
                    <ScrollToTopComponentWithRoute />
                    <div className="main" onMouseLeave={() => { if (!this.state.mouseOut) { this.setState({ mouseOut: true }); } }}>
                        {/*this.state.mouseOut ? <ExitModalComponentWithRoute /> : null*/}
                        <PromotionBarComponent />
                        <HeaderComponentWithRoute />
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route exact path="/about" component={AboutPage} />
                            <Route exact path="/booking/:step?" component={BookingPage} />
                            <Route exact path="/destinations" component={DestinationsPage} />
                            <Route exact path="/help-center" component={HelpCenterPage} />
                            <Route exact path="/bookingSummary/:bookingReference" component={BookingSummaryPage} />
                            <Route exact path="/budapest-airport-transfer" component={AirportTransfer} />
                            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
                            <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
                            <Route exact path="/group-transfer-hungary" component={GroupTransferPage} />
                            <Route exact path="/premium-transfer-airport" component={PremiumTransferPage} />
                            <Route exact path="/covid-19-safety-measures" component={CoronaVirusUpdatePage} />
                            {/* Landing pages */}
                            <Route exact path="/destinations/vienna-to-budapest-private-transfer" component={ViennaToBudapestTransferPage} />
                            <Route exact path="/destinations/budapest-to-vienna-private-transfer" component={BudapestToViennaTransferPage} />
                            <Route exact path="/destinations/budapest-to-debrecen-private-transfer" component={BudapestToDebrecenTransferPage} />
                            <Route exact path="/destinations/budapest-to-bratislava-private-transfer" component={BudapestToBratislavaTransferPage} />
                            <Route exact path="/destinations/budapest-to-heviz-private-transfer" component={BudapestToHevizTransferPage} />
                            <Route component={NotFoundPage} status={404} />
                        </Switch>
                        <FooterComponent />
                    </div>
                </Suspense>
            </Router>
        );
    }
}
