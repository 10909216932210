import React from "react";
import Spinner from 'react-bootstrap/Spinner';

export default function LoaderComponent() {
    return (
        <div className="text-center mb-5 mt-5">
            <Spinner animation="grow" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>

            <p className="h2 mt-2">We are working on it...</p>
        </div>
    );
};